import { PackageApi } from "@/features/package";
import { Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteContext } from "../hooks";
import styles from "./styles.less";

const FormItem = Form.Item;

export default () => {
  const { name } = useRouteContext();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [description, setDescription] = useState("");
  const [token, setToken] = useState("");

  return (
    <div className={styles.newButtonRow}>
      <Button type="primary" onClick={() => setVisible(true)}>
        Add new lock
      </Button>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title="Add new token"
        onOk={addToken}
        footer={[
          <Button key={1} onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button key={2} onClick={addToken} type="primary" disabled={description.trim() === ""}>
            Add token
          </Button>
        ]}
      >
        <FormItem label="Description">
          <Input
            onChange={e => setDescription(e.target.value)}
            value={description}
            placeholder="A simple description for your token"
          />
        </FormItem>
        <FormItem label="Token (Optional)">
          <Input
            onChange={e => setToken(e.target.value)}
            value={token}
            placeholder="Autogenerated if value is left empty"
            maxLength={40}
          />
        </FormItem>
      </Modal>
    </div>
  );

  async function addToken() {
    await dispatch(
      PackageApi.addPackageLock(name, {
        description: description.trim(),
        token: token.trim()
      })
    );
    setVisible(false);
  }
};
